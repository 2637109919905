import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import YouTube from 'react-youtube';

const HomePage = () => {
  const videoId = 'f1EyTv3BvG8';

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
        <Typography variant="h3" align="center">
          it&apos;s showtime
        </Typography>
        <Divider sx={{mt: 1, mb: 2}} />
        <Box mt={2} width="100%" textAlign="center">
          <Box style={{ margin: '0 auto' }}>
            <YouTube videoId={videoId} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HomePage;