import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Grid, Card, CardContent, Box, Divider, IconButton, Modal, Button, useMediaQuery } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useFirebase } from './Firebase';
import { getUIImagePath, UI_labels } from './musicUtils';

const Customization = () => {
  const { auth, user } = useFirebase();
  const [customization, setCustomization] = useState(null);
  const [name, setName] = useState(null);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false); // State to control the visibility of the modal
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleEditClick = (key) => {
    navigate(`/customize/${key}`);
  };

  useEffect(() => {
    const fetchCustomization = async () => {
      try {
        const response = await fetch(`/api/customization?refid=${user.displayName}`);
        let data = await response.json();
        // console.log('Customization Response:', data); // Log the customization response to the console
        if (data.error) {
          setError(data.error);
        } else {
          setName(data.name);
          delete data.name;

          // Fetch filenames of stamps
          const stampIDs = ['stamp_a', 'stamp_b', 'stamp_c', 'stamp_d', 'stamp_e', 'stamp_f', 'stamp_g', 'stamp_h']
            .map(key => data[key]);
          const stampResponse = await fetch('/api/stampFilenames', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ stampIDs }),
          });
          const stampData = await stampResponse.json();
          // console.log('Stamp Filenames Response:', stampData); // Log the stamp filenames response to the console
          if (stampData.error) {
            setError(stampData.error);
          } else {
            // Create a new object to store the updated data
            const updatedData = { ...data };

            // Update data with filenames of stamps
            stampIDs.forEach((id, index) => {
              updatedData[`stamp_${String.fromCharCode(97 + index)}`] = stampData.data[index];
            });

            setCustomization(updatedData);

            // Check for missing or null fields
            const expectedFields = ['bgm', 'subbg', 'nemsys', 'sysbg', 'stamp_a', 'stamp_b', 'stamp_c', 'stamp_d', 'stamp_e', 'stamp_f', 'stamp_g', 'stamp_h'];
            const missingFields = expectedFields.filter(field => {
              if (!data.hasOwnProperty(field)) {
                return true; // Include all fields if they are missing
              }
              if (field.startsWith('stamp')) {
                return data[field] === 0; // Include stamp fields if they are either missing or set to 0
              }
              return false; // Exclude all other fields from missingFields
            });

            if (missingFields.length > 0) {
              setShowModal(true); // Show the modal if there are missing or null fields

              // Make a POST request to the /api/customizationInitialize route
              fetch('/api/customizationInitialize', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ refid: user.displayName, fields: missingFields }),
              })
                .then((response) => response.json())
                .then((data) => {
                  console.log(data.message);
                })
                .catch((error) => {
                  console.error('Error:', error);
                });
            }
          }
        }
      } catch (error) {
        setError(error.message);
      }
    };

    if (user) {
      fetchCustomization();
    }
  }, [user]);

  const handleModalClose = () => {
    setShowModal(false);
    window.location.reload(); // Refresh the page since the customization fields have been initialized
  };

  return (
    <Container component="main" maxWidth="md">
      <div>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', mb: 2}}>
          <Box>
            <Typography variant={isSmallScreen ? 'h4' : 'h3'} align="left" sx={{fontWeight: 'bold', textOverflow: 'ellipsis'}}>
              UI Settings
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5" align="right" component="div">
              Player: 
            </Typography>
            <Typography variant="h4" align="right" component="div" sx={{fontWeight: 'bold'}}>
              {name}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{mt: 1, mb: 2}} />
        {error && <div>Error: {error}</div>}
        {customization && (
          <Grid container spacing={2}>
            {Object.entries(customization)
              .sort(([keyA], [keyB]) => {
                const indexA = keyA.startsWith('stamp') ? keyA.charCodeAt(6) - 97 : 0;
                const indexB = keyB.startsWith('stamp') ? keyB.charCodeAt(6) - 97 : 0;
                if (keyA === 'sysbg' && keyB.startsWith('stamp')) {
                  return -1;
                }
                if (keyB === 'sysbg' && keyA.startsWith('stamp')) {
                  return 1;
                }
                return indexA - indexB;
              })
              .map(([key, value]) => {
                const imagePath = value === 0 ? null : getUIImagePath(key, value);
                return (
                  <Grid item xs={6} sm={6} md={3} key={key}>
                    <Card>
                      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <Box sx={{ p: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography variant="h7" sx={{ fontWeight: 'bold', mx: 1 }}>{UI_labels[key]}</Typography>
                          <IconButton onClick={() => handleEditClick(key)}>
                            <EditIcon />
                          </IconButton>
                        </Box>
                        <Box sx={{ flexGrow: 1, p: 1, display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
                          {imagePath ? (
                            <img src={imagePath} alt={value} style={{ maxWidth: '100%' }} />
                          ) : (
                            <Typography variant="body1" sx={{ textAlign: 'center', my: 6 }}>Default</Typography>
                          )}
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                );
              })
            }
          </Grid>
        )}
      </div>
      {/* Modal */}
      <Modal
        open={showModal}
        onClose={handleModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, maxWidth: 400 }}>
          <Typography variant="h6" id="modal-title" align="center">
            Some of your customization fields appear to be missing or empty.
          </Typography>
          <Typography variant="body1" id="modal-description" align="center" sx={{ mt: 2 }}>
            This is normal, and they will now be initialized for you automatically.
          </Typography>
          <Button variant="contained" onClick={handleModalClose} sx={{ mt: 4, mx: 'auto', display: 'block' }}>
            OK
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

export default Customization;
