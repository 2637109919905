import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Container, Grid, Card, Box, IconButton, CardContent, CardActionArea, CardMedia, Typography, Tooltip, Divider, Modal, Backdrop, Fade } from '@mui/material';
import Alert from '@mui/material/Alert';
import { Snackbar } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import StopIcon from '@mui/icons-material/Stop';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { getUIImagePath, UI_labels } from './musicUtils';
import { useFirebase } from './Firebase';

const CustomizeEdit = () => {
  const { setting } = useParams();
  const [options, setOptions] = useState([]);
  const playingAudio = useRef({ audio: null, id: null, soundNumber: null });
  const [playingId, setPlayingId] = useState({ id: null, soundNumber: null });
  const { auth, user } = useFirebase();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [selectedOption, setSelectedOption] = useState(null); // Add state variable for selected option
  const [selectedSlot, setSelectedSlot] = useState(null); // Add state variable for selected slot
  const [modalOpen, setModalOpen] = useState(false); // Add state variable for modal

  useEffect(() => {
    const fetchOptions = async () => {
      const response = await fetch(`/api/customizationOptions/${setting}`);
      const data = await response.json();
      // console.log('Options data:', data);  // Log the data
      if (data && data.data) {
        setOptions(data.data);
      } else {
        console.error('Failed to fetch options:', data);
      }
    };
  
    fetchOptions();
  }, [setting]);

  const handlePlay = (option, soundNumber) => {
    // console.log(`handlePlay called with option: ${option}, soundNumber: ${soundNumber}`);
    // Get the id from the option
    const id = typeof option === 'object' ? option.id : option;

    // Get the filename for the sound file
    let filename;
    if (setting.startsWith('stamp_')) {
      // Construct the filename for a stamp
      filename = `/content/audio/stamp/${option.filename_se}.mp3`;
      console
    } else {
      // Construct the filename for a BGM, padding the id with zeros if it's a single digit
      filename = `/content/audio/bgm/custom_${String(id).padStart(2, '0')}_${soundNumber}.mp3`;
    }
  
    // If there's a currently playing audio, stop it and reset its play button
    if (playingAudio.current.audio) {
      playingAudio.current.audio.pause();
      playingAudio.current.audio.currentTime = 0;
    }

    // If the clicked play button is not the same as the currently playing one, play the new audio
    if (playingAudio.current.id !== id || playingAudio.current.soundNumber !== soundNumber) {
      const audio = new Audio(filename);
      audio.volume = 0.5; // Set the volume to 50%
      // console.log(`Created new Audio object with path: ${filename}`);
      audio.play() // .then(() => console.log('Audio is playing')).catch((error) => console.error('Failed to play audio:', error));

      // Add an 'ended' event listener to the audio object
      audio.addEventListener('ended', () => {
        playingAudio.current = { audio: null, id: null, soundNumber: null };
        setPlayingId({ id: null, soundNumber: null });
      });

      playingAudio.current = { audio, id, soundNumber };
      setPlayingId({ id, soundNumber });
      // console.log(`Updated playingAudio.current to: ${JSON.stringify(playingAudio.current)}`);
    } else {
      playingAudio.current = { audio: null, id: null, soundNumber: null };
      setPlayingId({ id: null, soundNumber: null });
      // console.log(`Reset playingAudio.current to: ${JSON.stringify(playingAudio.current)}`);
    }
  };

  // Stop the currently playing audio when the component is unmounted
  useEffect(() => {
    return () => {
      if (playingAudio.current.audio) {
        playingAudio.current.audio.pause();
        playingAudio.current.audio.currentTime = 0;
      }
    };
  }, []);

  const handleSelect = (option) => {
    setSelectedOption(option); // Store the selected option in state
    // Show modal when saving a stamp option
    if (setting.startsWith('stamp_')) {
      setModalOpen(true);
    } else {
      saveOption(option); // Call the saveOption function directly for non-stamp options
    }
  };
  
  const handleDefault = () => {
    handleSelect({ id: 0 }); // Call handleSelect with option.id set to 0
  };
  
  const handleSlotSelect = (slot) => {
    setSelectedSlot(slot);
    setModalOpen(false);
    saveOption(selectedOption, slot); // Pass the selected option and slot to saveOption
  };
  
  const saveOption = (option, slot) => {
    // Determine the final setting based on the selected slot
    const finalSetting = setting.startsWith('stamp_') ? `stamp_${slot}` : setting;
    // Get the id from the option
    const id = typeof option === 'object' ? option.id : option;
    // Log the final values before making the API call
    // console.log('Refid:', user.displayName, '- Setting:', finalSetting, '- Option:', id);
  
    // Make a POST request to the /api/customizationSave route
    fetch('/api/customizationSave', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refid: user.displayName, setting: finalSetting, option: id }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data.message);
        setSnackbarMessage(data.message);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error('Error:', error);
        setSnackbarMessage('Failed to save option');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  return (
    <Container component="main" maxWidth="md">
      <div>
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
          <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', mb: 2 }}>
          <Box>
            <Typography variant="h3" align="left" sx={{ fontWeight: 'bold' }}>
              {UI_labels[setting]}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!setting.startsWith('stamp_') && (
              <Button variant="contained" color="secondary" sx={{ fontWeight: 'bold', mr: 2 }} onClick={handleDefault}>
                Default
              </Button>
            )}
            <Button variant="contained" color="primary" sx={{ fontWeight: 'bold' }} startIcon={<ArrowBack />} onClick={() => window.location.href = '/customize'}>
              Back
            </Button>
          </Box>
        </Box>
        <Divider sx={{mt: 1, mb: 2}} />
        <Grid container spacing={0.75}>
        {
          [...options].sort((a, b) => Number(a) - Number(b)).map((option) => {
            const id = typeof option === 'object' ? option.id : option;
            const filename = typeof option === 'object' ? option.filename : `item_${setting}_${String(option).padStart(4, '0')}`;
            const imagePath = getUIImagePath(setting, typeof option === 'object' ? filename : option);
  
            return (
              <Grid item xs={6} sm={3} key={id}>
                <Card>
                  <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Box sx={{ flexGrow: 1, p: 1, display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
                      <img src={imagePath} alt={filename} style={{ maxWidth: '100%' }} />
                    </Box>
                    <Box sx={{ p: 0.5, mt: -0.5, mb: 0.25, display: 'flex', justifyContent: 'flex-end' }}>
                      {(setting === 'bgm' || option.filename_se) && (
                        <>
                          {setting === 'bgm' ? (
                            <Tooltip title="BGM">
                              <IconButton onClick={() => handlePlay(option, 0)} style={{ marginRight: 'auto' }}>
                                {playingId.id === id && playingId.soundNumber === 0 ? <StopIcon /> : <PlayArrowIcon />}
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <IconButton onClick={() => handlePlay(option, 0)} style={{ marginRight: 'auto' }}>
                              {playingId.id === id && playingId.soundNumber === 0 ? <StopIcon /> : <PlayArrowIcon />}
                            </IconButton>
                          )}
                          {setting === 'bgm' && (
                            <Tooltip title="Song Select">
                              <IconButton onClick={() => handlePlay(option, 1)} style={{ marginRight: 'auto' }}>
                                {playingId.id === id && playingId.soundNumber === 1 ? <StopIcon /> : <PlayArrowIcon />}
                              </IconButton>
                            </Tooltip>
                          )}
                        </>
                      )}
                      <IconButton onClick={() => handleSelect(option)}>
                        <CheckIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Card>
              </Grid>
            );
          })}
        </Grid>
  
        {/* Step 5: Conditionally render stamp slot selection grid and modal */}
        {setting.startsWith('stamp_') && (
          <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            closeAfterTransition
          >
            <Fade in={modalOpen}>
              <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, maxWidth: 400 }}>
                <Typography variant="h6" id="modal-title" align="center" >
                  Choose slot for stamp:
                </Typography>
                {/* <Divider sx={{mt: 2, mb: 3}} /> */}
                <Grid container spacing={2} sx={{mt: 2}}>
                  {/* Render the stamp slot selection grid */}
                  {['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'].map((slot) => (
                    <Grid item xs={6} sm={3} key={slot}>
                      <Card>
                        <CardActionArea onClick={() => handleSlotSelect(slot)}>
                          <CardContent>
                            <Typography variant="h5" align="center" sx={{fontWeight: 'bold'}}>
                              {slot.toUpperCase()}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Fade>
          </Modal>
        )}
      </div>
    </Container>
  );

};

export default CustomizeEdit;

