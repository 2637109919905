import React, { useState } from 'react';
import { Link as RouterLink, Navigate } from 'react-router-dom';
import { Button, TextField, Grid, Typography, Container, Box, Link } from '@mui/material';
import { useFirebase } from './Firebase';

const SignupForm = () => {
  const { auth, createUserWithEmailAndPassword } = useFirebase();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [navigate, setNavigate] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      setNavigate(true);
    } catch (error) {
        console.error(error);
        setError(error.message);
    }
  };

  if (navigate) {
    return <Navigate to="/" />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <div>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box mt={2}>
          {error && <Typography color="error">{error}</Typography>}
        </Box>
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={event => setEmail(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={event => setPassword(event.target.value)}
          />
          <Box mt={2}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              Sign Up
            </Button>
          </Box>
          <Grid container mt={2}>
            <Grid item>
              <Link component={RouterLink} to="/login" sx={{ color: '#9a67ea', textDecoration: 'none', fontWeight: 'bold' }}>
                {"Already have an account? Sign In"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default SignupForm;
