import React, { useState } from 'react';
import { Link as RouterLink, Navigate } from 'react-router-dom';
import { Button, TextField, Grid, Typography, Container, Box, Link } from '@mui/material';
import { useFirebase } from './Firebase';
import { sendPasswordResetEmail } from 'firebase/auth';

const LoginForm = () => {
  const { auth, user, signInWithEmailAndPassword } = useFirebase();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleForgotPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      alert('Password reset email sent!');
    } catch (error) {
      setError(error.message);
    }
  };

  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <div>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box mt={2}>
          {error && <Typography color="error">{error}</Typography>}
        </Box>
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={event => setEmail(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={event => setPassword(event.target.value)}
          />
          <Box mt={2}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              Sign In
            </Button>
          </Box>
          <Grid container mt={2}>
            <Grid item xs>
              <Link component={RouterLink} to="#" variant="body2" onClick={handleForgotPassword} sx={{ color: '#9a67ea', textDecoration: 'none', fontWeight: 'bold' }}>
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link component={RouterLink} to="/signup" variant="body2" sx={{ color: '#9a67ea', textDecoration: 'none', fontWeight: 'bold' }}>
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default LoginForm;
