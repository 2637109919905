import React, { useEffect, useState } from 'react';
import { useFirebase } from './Firebase';
import { difficultyTypeMitsuruToString, gradeToString, clearTypeToString, getJacketImage, getBorderColor, difficultyTypeToColor, difficultyTypeToAcronym } from './musicUtils';
import { Box, Container, Grid, Card, CardContent, Typography, CardMedia, Divider, useMediaQuery } from '@mui/material';

const Volforce = () => {
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [scores, setScores] = useState([]);
  const { user } = useFirebase(); // Get the user's refid from their Firebase Auth displayName
  const [metadata, setMetadata] = useState([]);  // State to hold the metadata
  const [overallVolforce, setOverallVolforce] = useState(0);  // State to hold the overall Volforce Rating
  const [top50, setTop50] = useState([]);  // State to hold the top 50 volforce scores
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!user) return;  // If the user is not logged in, do nothing
    if (isDataLoaded) return;  // If the data is already loaded, do nothing
    // Step 1: Get the player's score records
    fetch('/api/volforceScores', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        refid: user ? user.displayName : null,  // Check if user exists
      }),
    })
    .then(response => response.json())
    .then(scoreData => {
      setScores(scoreData);

      console.log("Debugging scoreData:", scoreData);

      // Step 2: Extract unique music IDs and music types from the scores
      const uniqueMusicData = extractUniqueMusicData(scoreData);
      
      // Step 3: Fetch metadata for the extracted music IDs and music types
      fetch('/api/volforceMetadata', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          musicData: uniqueMusicData,
        }),
      })
      .then(response => response.json())
      .then(metadataData => {
        setMetadata(metadataData);
  
        console.log("Debugging metadataData:", metadataData);

        // Step 4: Calculate Volforce for each score
        const calculatedScores = calculateVolforce(scoreData, metadataData);
        const sortedScores = calculatedScores.sort((a, b) => b.volforce - a.volforce);

        // Calculate the overall Volforce Rating
        const overallVFRating = sortedScores.slice(0, 50).reduce((sum, score) => sum + score.volforce, 0);
        setOverallVolforce(parseFloat(overallVFRating.toFixed(3)));  // Round to 3 decimal places

        setTop50(sortedScores.slice(0, 50));
      });
    });
    setIsDataLoaded(true);
  }, [user, scores, metadata]);  // Add dependency on scores and metadata  
  
  // Helper function to extract unique music_id and music_type combinations
  const extractUniqueMusicData = (scoreData) => {
    const uniqueMusicData = [];
    const seen = new Set();
  
    scoreData.forEach(({ music_id, music_type }) => {
      const key = `${music_id}-${music_type}`;
      if (!seen.has(key)) {
        seen.add(key);
        uniqueMusicData.push({
          music_id,
          music_type: difficultyTypeMitsuruToString[music_type], // Convert to string
        });
      }
    });
  
    return uniqueMusicData;
  };

  // Helper function to get the grade multiplier
  const getVFGradeMult = (grade) => {
    const gradeMultipliers = {
      10: 1.05,
      9: 1.02,
      8: 1.00,
      7: 0.97,
      6: 0.94,
      5: 0.91,
      4: 0.88,
      3: 0.85,
      2: 0.82,
      1: 0.80,
    };
    return gradeMultipliers[grade] || 0.0;
  };

  // Helper function to get the clear multiplier
  const getVFClearMult = (clearType) => {
    const clearMultipliers = {
      5: 1.1,
      4: 1.05,
      3: 1.02,
      2: 1.0,
      1: 0.5,
    };
    return clearMultipliers[clearType] || 0.0;
  };

  // Helper function to calculate Volforce for each score
  const calculateVolforce = (scoreData, metadataData) => {
    return scoreData.map(score => {
      const meta = metadataData.data.find(m => m.music_id === score.music_id && m.difficulty_type === difficultyTypeMitsuruToString[score.music_type]);
      if (!meta) return null;

      const gradeMult = getVFGradeMult(score.grade);
      const clearMult = getVFClearMult(score.clear);
      
      const raw = ((meta.difnum * 2) * (score.score / 10000000) * gradeMult * clearMult);
      const volforce = Math.floor(raw * 10) / 1000;

      return { ...score, ...meta, volforce };
    }).filter(Boolean);
  };

  const CustomCardMedia = ({ defaultImage, fallbackImage, placeholderImage, alt }) => {
    const [currentImage, setCurrentImage] = useState(defaultImage);
    
    useEffect(() => {
      const img = new Image();
      img.src = currentImage;
      
      img.onload = () => {
        setCurrentImage(currentImage); // image loaded successfully, no action needed
      };
      
      img.onerror = () => {
        // Set the next image to try
        if (currentImage === defaultImage) {
          setCurrentImage(fallbackImage);
        } else if (currentImage === fallbackImage) {
          setCurrentImage(placeholderImage);
        }
      };
    }, [currentImage, defaultImage, fallbackImage, placeholderImage]);
    
    return (
      <CardMedia
        component="img"
        sx={{ width: "90px", height: "90px", flexShrink: 0, borderRadius: '6px', boxShadow: '0px 0px 7px #ffffff45'}}
        image={currentImage}
        alt={alt}
      />
    );
  };

  return (
    <Container>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', mb: 2}}>
        <Box>
          <Typography variant={isSmallScreen ? 'h4' : 'h2'} align="left"  sx={{fontWeight: 'bold'}}>
            Volforce Info
          </Typography>
        </Box>
        <Box>
          <Typography variant="h5" align="right" component="div">
            Volforce Rating: 
          </Typography>
          <Typography variant="h3" align="right" component="div" sx={{fontWeight: 'bold'}}>
            {overallVolforce.toFixed(3)}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{mt: 1, mb: 2}} />
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {top50.map((score, index) => {

          const { defaultImage, fallbackImage } = getJacketImage({ imageID: score.music_id, difficultyType: difficultyTypeMitsuruToString[score.music_type] });
          const borderColor = getBorderColor(score.clear, score.grade);
          const diffAcronym = difficultyTypeToAcronym(difficultyTypeMitsuruToString[score.music_type], score.inf_ver);
          
          return (
            <Box key={index} sx={{ minWidth: 200, flexBasis: 'calc(20% - 12px)', margin: 0.75 }}>
              <Card 
                sx={{ 
                  minWidth: 180, 
                  flexDirection: 'column',
                  background: score.clear === 5
                    ? "linear-gradient(to top left, #FF9AA299 0%, #FFB7B299 5%, #FFDAC199 10%, #E2F0CB99 15%, #B5EAD799 20%, #C7CEEA99 25%, #9AA2FF99 30%, #20202099 35%, transparent), linear-gradient(to bottom right, #202020, transparent)" // replace with your own gradients
                    : `linear-gradient(to top left, ${borderColor}, #202020 35%)`
                }}
              >
                <Box display="flex" flexDirection="column" p={1} sx={{ position: 'relative' }}>  {/* Note the position: 'relative' */}
                  <Box display="flex" justifyContent="space-between">
                    <Box sx={{ position: 'relative' }}>  {/* Make this Box position 'relative' */}
                      <CustomCardMedia
                        defaultImage={defaultImage}
                        fallbackImage={fallbackImage}
                        placeholderImage="/content/jk_placeholder.png"
                        alt={score.title_name || "Unknown Song"}
                      />
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '5px',
                          right: '-33px',
                          backgroundColor: difficultyTypeToColor[diffAcronym],
                          borderColor: 'white',
                          color: 'white',
                          borderRadius: '4px',
                          padding: '0 5px 2px 4px',
                          fontSize: '0.85em',
                          fontWeight: 'bold',
                          textShadow: '1.5px 1.5px 2px black',
                          boxShadow: '0px 0px 8px #ffffffEE'
                        }}
                      >
                        {diffAcronym} {score.difnum}
                      </Box>
                    </Box>
                    <Box>
                      <Box sx={{ marginTop: -0.5 }}>
                        <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', textAlign: 'right', textShadow: '0px 0px 7px #ffffff77' }}> 
                          {(score.volforce * 100).toFixed(1)}
                        </Typography>
                        <Typography variant="h7" component="div" sx={{ textAlign: 'right', color: '#d1d1d1', marginTop: '6px' }}>
                          {score.score}
                        </Typography>
                      </Box>
                      <Box display="flex" justifyContent="flex-end" sx={{ marginTop: '6px' }}>
                        <Box sx={{ backgroundColor: '#4f51aed4', padding: '3px', borderRadius: '2px', boxShadow: '0px 0px 7px #ffffff45'}}>
                          <Typography variant="body2" component="div" sx={{ fontWeight: 'bold' }}>
                            {gradeToString[score.grade]} | {clearTypeToString[score.clear]}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box p={1} mt={-1}>
                  <Typography 
                    variant="body2" 
                    component="div" 
                    sx={{ 
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap', 
                      overflow: 'hidden', 
                      textOverflow: 'ellipsis',
                      maxWidth: '100%',
                      textShadow: '1.5px 1.5px 1.5px #00000066',
                    }}
                  >
                    {score.title_name || "Unknown Song"}
                  </Typography>
                </Box>
              </Card>
            </Box>
          );
        })}
      </Box>
    </Container>
  );  
  
};

export default Volforce;
