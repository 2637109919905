import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './Navbar';
import Loading from './Loading';
import TierList from './TierList';
import HomePage from './HomePage';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';
import UserSettings from './UserSettings';
import Volforce from './Volforce';
import Customize from './Customize';
import CustomizeEdit from './CustomizeEdit';
import './App.css';
import { CssBaseline, ThemeProvider, createTheme, Box } from '@mui/material';
import { useFirebase } from './Firebase';
import BackToTopButton from './BackToTopButton';

const App = () => {
  const { user, loading } = useFirebase();
  const theme = createTheme({
    palette: {
      mode: 'dark',
    },
    typography: {
        fontFamily: 'Plus Jakarta Sans',
        h3: {
            fontWeight: 600,
        },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {loading ? (
        <Loading />
      ) : (
        <Router>
          <Navbar />
          <Routes>
            <Route path="/" element={user ? <HomePage /> : <Navigate to="/login" />} />
            <Route path="/tier-list/:page" element={user ? <TierList key={window.location.pathname} /> : <Navigate to="/login" />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/signup" element={<SignupForm />} />
            <Route path="/user-settings" element={user ? <UserSettings /> : <Navigate to="/login" />} />
            <Route path="/volforce" element={<Volforce />} />
            <Route path="/customize" element={<Customize />} />
            <Route path="/customize/:setting" element={<CustomizeEdit />} />
          </Routes>
        </Router>
      )}
      <BackToTopButton />
    </ThemeProvider>
  );
};

export default App;
