import React, { useState, useEffect } from 'react';
import { Button, TextField, Grid, Typography, Container, Box, Divider } from '@mui/material';
import { useFirebase } from './Firebase';

const UserSettings = () => {
  const { auth, user, updateEmail, updatePassword, updateUserProfile } = useFirebase();
  const [email, setEmail] = useState(user ? user.email : '');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [cardId, setCardId] = useState(''); // new state variable for card ID
  const [pin, setPin] = useState(''); // new state variable for PIN
  const [error, setError] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    if (user) {
      setEmail(user.email);
    }
  }, [user]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    try {
      if (user.email !== email) {
        await updateEmail(auth.currentUser, email);
      }
      if (password) {
        await updatePassword(auth.currentUser, password);
      }
      setError(null);
      setIsUpdated(true);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleCardSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`/api/refid?cardid=${cardId}&pin=${pin}`);
      const data = await response.json();
      if (data.error || !data.refid) {
        setError(data.error || "Card ID and PIN do not match any existing profile");
        return;
      }
      await updateUserProfile(auth.currentUser, { displayName: data.refid });
      setError(null);
      setIsUpdated(true);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <div>
        <Typography component="h1" variant="h5"  sx={{fontWeight: 'bold'}}>
          User Settings
        </Typography>
        <Divider sx={{mt: 1, mb: 2}} />
        <Box mt={2}>
          {error && <Typography color="error">{error}</Typography>}
          {isUpdated && <Typography color="primary">Settings updated successfully</Typography>}
        </Box>
        <form noValidate onSubmit={handleCardSubmit}>
          <Typography component="h2" variant="h6" mt={4}>
            Link Card
          </Typography>
          {user && user.displayName && (
            <Typography variant="body2" color="textSecondary" mt={1}>
              Current ID: {user.displayName}
            </Typography>
          )}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="cardId"
            label="Card ID"
            name="cardId"
            value={cardId}
            onChange={event => setCardId(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="pin"
            label="PIN"
            type="password"
            id="pin"
            value={pin}
            onChange={event => setPin(event.target.value)}
          />
          <Box my={2}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              Link Card
            </Button>
          </Box>
        </form>
        <Divider sx={{mt: 6}} />
        <form noValidate onSubmit={handleSubmit}>
          <Typography component="h2" variant="h6" mt={4}>
            Change Email/Password
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={event => setEmail(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="New Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={event => setPassword(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="confirm-password"
            label="Confirm New Password"
            type="password"
            id="confirm-password"
            autoComplete="current-password"
            value={confirmPassword}
            onChange={event => setConfirmPassword(event.target.value)}
          />
        <Box mt={2}>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
            >
                Update Settings
            </Button>
        </Box>
        </form>
      </div>
    </Container>
  );
};

export default UserSettings;