import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Toolbar, Typography, IconButton, MenuItem, Menu, Button, Avatar } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import { useFirebase } from './Firebase';
import CryptoJS from 'crypto-js';

const StyledAppBar = styled(AppBar)`
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
`

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  flex: 1;
`

const NavBar = () => {
  const { auth, user } = useFirebase();
  const [tierListAnchorEl, setTierListAnchorEl] = React.useState(null);
  const [avatarEl, setAvatarEl] = React.useState(null); // new state variable for avatar menu
  const [pages, setPages] = useState([]);
  const tierListOpen = Boolean(tierListAnchorEl);
  const avatarOpen = Boolean(avatarEl); // new variable for avatar menu open state

  // Generate Gravatar URL
  const gravatarUrl = user ? `https://www.gravatar.com/avatar/${CryptoJS.MD5(user.email.trim().toLowerCase())}` : '';

  useEffect(() => {
    fetch('/api/pages')
      .then((response) => response.json())
      .then((data) => {
        if (data.data.length > 0) {
          setPages(data.data);
        }
      });
  }, []);

  const handleTierListMenu = (event) => {
    setTierListAnchorEl(event.currentTarget);
  };

  const closeTierListMenu = () => {
    setTierListAnchorEl(null);
  };

  const handleAvatarMenu = (event) => {
    setAvatarEl(event.currentTarget);
  };

  const handleAvatarClose = () => {
    setAvatarEl(null);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <StyledAppBar position="sticky" sx={{ mb: 6 }}>
      <Toolbar sx={{ display: 'flex', alignItems: 'center'}}>
      <StyledBox sx={{justifyContent: 'flex-start'}}>
        <IconButton edge="start" color="inherit" aria-label="logo" component={RouterLink} to="/">
          <img src="/content/logo.png" alt="Logo" style={{width: '40px'}}/>
        </IconButton>
        <Typography variant="h6" component={RouterLink} to="/" style={{ textDecoration: 'none', color: 'white' }}>
          Mitsuru
        </Typography>
        <Box borderLeft={1} borderColor="divider" sx={{ mx: 1 }} />
        <Button variant="text" color="inherit" onClick={handleTierListMenu} sx={{ mx: 0.5 }}>
          Tiers
        </Button>
        <Button variant="text" color="inherit" component={RouterLink} to="/volforce" sx={{ mx: 0.5 }}>
          Volforce
        </Button>
        <Button variant="text" color="inherit" component={RouterLink} to="/customize" sx={{ mx: 0.5 }}>
          Customize
        </Button>
        <Menu
          id="menu-appbar"
          anchorEl={tierListAnchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          keepMounted
          open={tierListOpen}
          onClose={closeTierListMenu}
          sx={{ mt: 1 }}
        >
        {pages.map(({ difnum, tier_type }) => (
          <MenuItem key={`${difnum}-${tier_type}`} onClick={closeTierListMenu} component={RouterLink} to={`/tier-list/${difnum}-${tier_type}`}>
            <Typography component="span" sx={{ fontWeight: 'bold', mr:0.5 }}>{difnum}</Typography>
            {`${tier_type.toUpperCase()}`}
          </MenuItem>
        ))}
        </Menu>
      </StyledBox>
      {user && (
        <div>
          <IconButton onClick={handleAvatarMenu} size="small">
            <Avatar alt={user.email} src={gravatarUrl} /> {/* Use the Gravatar URL here */}
          </IconButton>
          <Menu
            id="avatar-menu"
            anchorEl={avatarEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            open={avatarOpen}
            onClose={handleAvatarClose}
          >
            <MenuItem onClick={handleAvatarClose} component={RouterLink} to="/user-settings">Settings</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>
      )}
      </Toolbar>
    </StyledAppBar>
  );
};

export default NavBar;
