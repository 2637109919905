import React, { useState, useEffect } from 'react';
import { Fab } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';


const BackToTopButton = () => {
    const [showScroll, setShowScroll] = useState(false);      //Back to top state handling

    const checkScrollTop = () => {
        if (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300) {
          setShowScroll(true);
        } else {
          setShowScroll(false);
        }
      };

      const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };    
    
    useEffect(() => {
        window.addEventListener("scroll", checkScrollTop, false);
        return () => {
            window.removeEventListener("scroll", checkScrollTop, false);
        };
    }, []);

    return (
        <Fab id="back-to-top" className={showScroll ? "back-to-top-visible" : null } color="secondary" size="small" aria-label="scroll back to top" onClick={scrollTop}>
            <ArrowUpwardIcon />
        </Fab>
    );
    
};

export default BackToTopButton;