import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Select, MenuItem, Typography, Container, Card, CardMedia, Divider, CircularProgress, useMediaQuery, Fab } from '@mui/material';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import MusicInfoModal from './MusicInfoModal';
import { useFirebase } from './Firebase';
import { getJacketImage, difficultyTypeToAcronym, difficultyTypeToColor, getBorderColor, getOverlayColor, difficultyTypeToMitsuru } from './musicUtils';
import Loading from './Loading';

const TierList = () => {
  const { page } = useParams();
  const [difnum, tier_type] = page.split('-');
  const [tierContent, setTierContent] = useState([]);
  const [imageSize, setImageSize] = useState(100);
  const [selectedMusicID, setSelectedMusicID] = useState(null);
  const [selectedDifficulty, setSelectedDifficulty] = useState(null);
  const [scores, setScores] = useState([]);
  const pageTitle = `Lv. ${difnum} - ${tier_type.toUpperCase()}`;
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { user } = useFirebase();
  const tierColumn = {
    's': 'tier_s',
    'puc': 'tier_puc',
    'clear': 'tier_clear',
  }[tier_type];
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (user) {
      setLoading(true);
      fetch(`/api/tiers/${page}`)
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          if (Array.isArray(response.data)) {
            const tierImages = {};
            for (const row of response.data) {
              const tier = row[tierColumn];
              if (!tierImages[tier]) {
                tierImages[tier] = [];
              }
              tierImages[tier].push({
                imageID: row.music_id,
                difficultyType: row.difficulty_type,
                infVer: row.inf_ver,
              });
            }
            const sortedTiers = Object.entries(tierImages)
              .map(([tier, images]) => ({ tier: tier !== 'null' ? tier : 'Unrated', label: `Tier ${tier !== 'null' ? tier : 'Unrated'}`, images }))
              .sort((a, b) => a.tier - b.tier);
            setTierContent(sortedTiers);

            // Fetch scores from the server
            const scoresToFetch = sortedTiers.flatMap(({ images }) => images.map(({ imageID, difficultyType }) => ({
              music_id: Number(imageID),
              music_type: Number(difficultyTypeToMitsuru[difficultyType]),
            })));
            fetch('/api/tierListScores', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ refid: user.displayName, scores: scoresToFetch }),
            })
              .then((response) => response.json())
              .then((response) => {
                console.log('Server response:', response);
                if (Array.isArray(response)) {
                  setScores(response);
                } else {
                  console.error('Server response is not an array:', response);
                  setScores([]);
                }
              });
          } else {
            console.error('Data is not an array:', response.data);
          }
          setLoading(false);
        });
    }
  }, [page, user]);

  if (loading) {
    return <Loading />;
  }

  const handleImageSizeChange = (event) => {
    setImageSize(event.target.value);
  };

  const handleSelect = (event, musicID, difficulty) => {
    event.preventDefault();
    setSelectedMusicID(musicID);
    setSelectedDifficulty(difficulty);
    setIsModalOpen(true);
  };

  return (
    <>
      <Container>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', mb: 5}}>
            <Box>
              <Typography variant={isSmallScreen ? 'h5' : 'h4'} align="left" sx={{fontWeight: 'bold'}}>{pageTitle}</Typography>
              <Typography variant="body1" align="left">Click on a jacket to view song details.</Typography>
            </Box>
            <Select
              value={imageSize}
              onChange={handleImageSizeChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value={75}>Small</MenuItem>
              <MenuItem value={100}>Medium</MenuItem>
            </Select>
          </Box>
          <ImageGrid page={page} tierContent={tierContent} imageSize={imageSize} onSelect={handleSelect} scores={scores} difnum={difnum} />
        </Box>
      </Container>
      {selectedMusicID && (
        <MusicInfoModal
          musicID={selectedMusicID}
          difficulty={selectedDifficulty}
          open={isModalOpen}
          onClose={() => {
            setIsModalOpen(false); // Close the modal
          }}
          onExited={() => {
            setSelectedMusicID(null); // Reset selectedMusicID after the modal has finished exiting
          }}
        />
      )}
    </>
  );
};

const ImageGrid = ({ page, tierContent, imageSize, onSelect, scores, difnum }) => {
  const getScore = (music_id, music_type) => scores.find(score => score.music_id === music_id && score.music_type === music_type);

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
      {tierContent.map((tier, index) => (
        <Box key={`${page}-tier-${index}`} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', mb: 4 }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
          <Typography variant="h5" align="left" sx={{ fontWeight: 'bold' }}>
            <span style={{ fontSize: '1.5em' }}>{difnum}</span>
            <span style={{ fontSize: '1.2em' }}>.{tier.tier}</span>
          </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
              <LibraryMusicIcon fontSize="small" sx={{ ml:1.25, mr:0.5}} />
              <Typography variant="body" align="left">{tier.images.length}</Typography>
            </Box>
          </Box>
          <Divider sx={{ width: '100%', mt: 1, mb: 2.5}} />
          <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
            {tier.images.map((image) => {
              const { defaultImage, fallbackImage } = getJacketImage(image);
              const score = getScore(image.imageID, Number(difficultyTypeToMitsuru[image.difficultyType]));
              const borderColor = score ? getBorderColor(score.clear, score.grade) : 'rgba(200, 200, 200, 0.15)';
              const overlayColor = getOverlayColor(score ? score.clear : null);
              return (
                <Box 
                  key={`${page}-image-${image.imageID}-${image.difficultyType}`} 
                  sx={{ 
                    width: `${imageSize}px`, 
                    height: `${imageSize}px`, 
                    m: 0.25,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '7px',
                    position: 'relative',
                    backgroundColor: borderColor,
                    padding: '4px', // Adjust this value to change the "border" thickness
                  }}
                  className={borderColor === 'rainbow-border' ? borderColor : ''}
                  onClick={(event) => onSelect(event, image.imageID, image.difficultyType)}
                >
                  <Card
                    key={`${page}-image-${image.imageID}`} 
                    sx={{ 
                      width: '100%', 
                      height: '100%', 
                      position: 'relative',
                      borderRadius: '4px',
                      boxShadow: '2px 2px 6px 1px rgba(0, 0, 0, 0.6)',
                    }}
                  >  
                    <CardMedia
                      component="img"
                      image="/content/jk_placeholder.png"
                      aria-hidden="true"
                      sx={{ 
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%', 
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: '8px',
                        opacity: 0.3,
                      }}
                    />
                    <CardMedia
                      component="img"
                      image={defaultImage}
                      alt={`Music ID ${image.imageID}`}
                      title={`Music ID ${image.imageID}`}
                      aria-hidden="true"
                      onError={(e) => {
                        e.target.onerror = null; 
                        e.target.src = fallbackImage;
                      }}
                      sx={{ 
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%', 
                        height: '100%',
                        objectFit: 'cover',
                        fontSize: 0,
                      }}
                    />
                    <div className={overlayColor}></div>
                  </Card>
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: '7px',
                      left: '7px',
                      backgroundColor: difficultyTypeToColor[difficultyTypeToAcronym(image.difficultyType, image.infVer)],
                      color: 'white',
                      borderRadius: '5px',
                      padding: '0 5px 2px 4px',
                      fontSize: '0.65em',
                      fontWeight: 'bold',
                      textShadow: '1px 1px 2px black',
                      opacity: 0.75,
                    }}
                  >
                    {difficultyTypeToAcronym(image.difficultyType, image.infVer)}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      ))}
    </Box>
  );
};


export default TierList;