import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Table, TableBody, TableRow, TableCell, Fade, Backdrop, Grid} from '@mui/material';
import { difficultyTypeToAcronym, difficultyTypeToColor, getJacketImage } from './musicUtils';

const MusicInfoModal = ({ musicID, difficulty, open, onClose }) => {
    const [musicData, setMusicData] = useState(null);
    const [difficulties, setDifficulties] = useState([]);

    useEffect(() => {
        if (musicID) {
            fetch(`/api/music/${musicID}`)
            .then((response) => response.json())
            .then((data) => {
              console.log(data); // Log the server response
              const difficultyData = data.data.find((diff) => diff.difficulty_type === difficulty);
              setMusicData({ ...data.data[0], difficulty: difficultyData });
              setDifficulties(data.data);
            });
        }
      }, [musicID, difficulty]);

    if (!musicData) {
        return null;
    }

    console.log('Rendering MusicInfoModal', { musicID, open }); // Add this line

    const jacketImageInfo = {
        imageID: musicData.id,
        difficultyType: musicData.difficulty.difficulty_type,
      };
      
    const { defaultImage, fallbackImage } = getJacketImage(jacketImageInfo);

    return (
        <Modal
            open={open}
            onClose={onClose}
            closeAfterTransition
            disableScrollLock
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
            style={{
                position: 'sticky',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Fade in={open} unmountOnExit>
                <Box 
                    sx={{ 
                    position: 'absolute',
                    left: '50%', 
                    top: '-50vh',
                    transform: 'translate(-50%, -50%)', 
                    width: '800', 
                    // height: 'auto',
                    bgcolor: 'rgba(255, 255, 255, 0.1)', 
                    boxShadow: 24, 
                    outline: 0,
                    px: 2,
                    py: 3.5,
                    borderRadius: '15px',
                    border: '4px solid rgba(255, 255, 255, 0.2)',
                    backdropFilter: 'blur(15px)',
                    }}
                >
                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid item xs={12} sm={4} md={4}>
                            <Box sx={{ flex: 2, borderRadius: '7px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <img 
                                    src={defaultImage} 
                                    alt={`Music ID: ${musicID}`} 
                                    onError={(e) => {
                                    e.target.onerror = null; 
                                    e.target.src = fallbackImage;
                                    }}
                                    style={{ width: '165px', borderRadius: '12px', alignItems: 'center', boxShadow: '0px 0px 15px 3px rgba(255, 255, 255, 0.3)'}}
                                />
                                <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2 }}>
                                    {difficulties.map((difficultyData) => (
                                        <Box
                                        key={difficultyData.difficulty_type}
                                        sx={{
                                            backgroundColor: difficultyTypeToColor[difficultyTypeToAcronym(difficultyData.difficulty_type, difficultyData.inf_ver)],
                                            color: 'white',
                                            borderRadius: '8px',
                                            padding: '0 5px 2px 4px',
                                            fontSize: '0.75em',
                                            fontWeight: 'bold',
                                            textShadow: '1px 1px 2px black',
                                            mx: 0.5,
                                        }}
                                        >
                                        {difficultyTypeToAcronym(difficultyData.difficulty_type, difficultyData.inf_ver)}
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={8} lg={8} container alignItems="center" justifyContent="center">
                            <Box sx={{ flex: 4, mx: 2 }}>
                                <Box sx={{ mb: 1 }}><Typography variant="h4" sx={{ fontWeight: 'bold' }} >{musicData.title_name}</Typography></Box>
                                <Box sx={{ mb: 3 }}><Typography variant="h6">{musicData.artist_name}</Typography></Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box sx={{ flex: 4, mx: 2 }}>
                                <Box sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', mr:1}}>LEVEL</Typography>
                                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>{musicData.difficulty.difnum}</Typography>
                                </Box>
                                <Box sx={{ mb: 1 }}>
                                    <Typography variant="h6" mb={0.5}>Effected by:</Typography>
                                    <Typography variant="h7">{musicData.difficulty.effected_by}</Typography>
                                </Box>
                                <Box sx={{ mb: 1 }}>
                                    <Typography variant="h6" mb={0.5}>Illustrator:</Typography>
                                    <Typography variant="h7">{musicData.difficulty.illustrator}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box sx={{ flex: 3, mx: 2}}>
                                <Table sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', borderRadius: '10px'}}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Server best:</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Personal best:</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                        {Array(4).fill().map((_, index) => (
                                            <TableRow key={index} sx={index === 3 ? { '& > td': { borderBottom: 0 } } : {}}>
                                                <TableCell>Rival {index + 1}:</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                {/* Placeholder for server info */}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    );

};

export default MusicInfoModal;