// Convert difficulty_type from its Mitsuru number to corresponding string value
export const difficultyTypeMitsuruToString = {
    '0' : 'novice',
    '1' : 'advanced',  
    '2' : 'exhaust',
    '3' : 'infinite',
    '4' : 'maximum',
};

// Convert difficulty_type from its string value to corresponding Mitsuru number
export const difficultyTypeToMitsuru = {
    'novice' : '0',
    'advanced' : '1',
    'exhaust' : '2',
    'infinite' : '3',
    'maximum' : '4',
};

// Convert difficulty_type from its string value to corresponding number
export const difficultyTypeToNumber = {
    'novice': '1',
    'advanced': '2',
    'exhaust': '3',
    'infinite': '4',
    'maximum': '5',
};
  
// Get the corresponding acronym for the difficultyType
export const difficultyTypeToAcronym = (difficultyType, infVer) => {
    if (difficultyType === 'infinite') {
        switch (infVer) {
            case 2: return 'INF';
            case 3: return 'GRV';
            case 4: return 'HVN';
            case 5: return 'VVD';
            case 6: return 'XCD';
            default: return 'INF';
        }
        } else {
        switch (difficultyType) {
            case 'novice': return 'NOV';
            case 'advanced': return 'ADV';
            case 'exhaust': return 'EXH';
            case 'maximum': return 'MXM';
            default: return '';
        }
    }
};
  
// List of colors to represent each actual type of difficulty
export const difficultyTypeToColor = {
    'NOV': 'purple',
    'ADV': 'yellow',
    'EXH': 'red',
    'MXM': 'powderblue',
    'INF': 'fuchsia',
    'GRV': 'orange',
    'HVN': 'skyblue',
    'VVD': 'pink',
    'XCD': 'dodgerblue',
};

// Determine border color based on clear and grade values
export const getBorderColor = (clear, grade) => {
    if (clear <= 1) {
      return 'salmon'; // Failed
    } else if (clear >= 2 && clear <= 3) {
      if (grade <= 7) {
        return 'lightgreen'; // Clear
      } else if (grade === 8) {
        return 'rgba(50, 193, 226, 0.25)'; // AA-AAA
      } else if (grade === 9) {
        return 'rgba(148, 251, 255, 0.55)'; // AAA+
      } else if (grade === 10) {
        return 'rgba(255, 253, 145, 0.9)'; // S
      }
    } else if (clear === 4) {
      return 'deeppink'; // UC
    } else if (clear === 5) {
      return 'rainbow-border'; // PUC
    }
    return 'rgba(200, 200, 200, 0.15)'; // Default color if none of the conditions are met
};

export const getOverlayColor = (clear) => {
  if (clear === 5) {
    return 'puc'; // PUC
  }
  if (clear === null) {
    return 'notplayed'; // No score data
  }
  return 'notpuc'; // Played but not PUC
};

// Convert clear type from its number to corresponding string value
export const clearTypeToString = {
    '0': 'U',
    '1': 'FAIL',
    '2': 'C',
    '3': 'EXC',
    '4': 'UC',
    '5': 'PUC',
};

// Convert grade from its number to corresponding string value
export const gradeToString = {
    '0': 'UNPLAYED',
    '1': 'D',
    '2': 'C',
    '3': 'B',
    '4': 'A',
    '5': 'A+',
    '6': 'AA',
    '7': 'AA+',
    '8': 'AAA',
    '9': 'AAA+',
    '10': 'S',
}

// Gets jacket filenames/paths for specific difficulty, and fallback for songs without difficulty-specific jackets
export const getJacketImage = (image) => {
    const baseImageID = image.imageID.toString().padStart(4, '0');
    const difficultyNumber = difficultyTypeToNumber[image.difficultyType];
    const defaultImage = `/content/jackets/jk_${baseImageID}_${difficultyNumber}_s.webp`;
    const fallbackImage = `/content/jackets/jk_${baseImageID}_1_s.webp`;
  
    return { defaultImage, fallbackImage };
  };

// Gets image filenames/paths for UI customization options
export const getUIImagePath = (key, value) => {
  switch (true) {
    case key === 'bgm':
      return `/content/ui/valgene_item/item_bgm_${value}.webp`;
    case key === 'subbg':
      return `/content/ui/valgene_item/item_bg_${value}.webp`;
    case key === 'nemsys':
      return `/content/ui/valgene_item/item_nemsys_${value}.webp`;
    case key === 'sysbg':
      return `/content/ui/valgene_item/item_sysbg_${value}.webp`;
    case /^stamp_/.test(key):
      return `/content/ui/chat_stamp/${value}.webp`;
    default:
      return '';
  }
};

// Maps UI customization keys to their corresponding labels
export const UI_labels = {
  // appeal_card: 'Appeal Card',
  bgm: 'BG Music',
  subbg: 'Subscreen BG',
  nemsys: 'Deck Skin',
  sysbg: 'System BG',
  stamp_a: 'Stamp A',
  stamp_b: 'Stamp B',
  stamp_c: 'Stamp C',
  stamp_d: 'Stamp D',
  stamp_e: 'Stamp E',
  stamp_f: 'Stamp F',
  stamp_g: 'Stamp G',
  stamp_h: 'Stamp H',
};